import { Form } from '@formio/react';
import { debounce, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { createResponse, saveResponseProgress, updateResponse } from '../api';
import CalibrateLogo from '../assets/calibrate.png';
import Loader from './Loader';
import { progressBar } from './progress_bar';
import ThankYou from './ThankYou';

// Overwrite formio templates.
const formOptions = {
    templates: {
        wizardHeader: {
            form: progressBar,
        },
    },
};

// Work around for formio/react issue.
// https://github.com/formio/react/pull/425
// eslint-disable-next-line react/forbid-foreign-prop-types
Form.propTypes.options = PropTypes.shape({
    readOnly: PropTypes.bool,
    noAlerts: PropTypes.bool,
    i18n: PropTypes.object,
    template: PropTypes.string,
    saveDraft: PropTypes.bool,
});

const calibrateFormschema = require('../schema/calibrateFormSchema.json');

const useStyles = makeStyles(() => ({
    dialog: {
        minWidth: '38rem',
        height: '100%',
    },
    title: {
        backgroundColor: 'lightgrey',
        width: '100%',
        border: '15px',
    },
    closeIcon: {
        height: 24,
        width: 24,
        fontSize: 14,
        cursor: 'pointer',
        marginRight: 10,
    },
    dialogActionsRoot: {
        justifyContent: 'flex-start',
    },

    IconFont: {
        lineHeight: '0px',
    },
    dialogTitle: {
        padding: '14px 20px 0px 22px',
        borderBottom: '1px solid',
        paddingBottom: '2%',
    },
    footer: {
        paddingRight: '29px',
        border: '1px solid #efeff0',
    },
    addsave: {
        whiteSpace: 'nowrap',
    },
    paperstyles: {
        marginRight: '0px',
    },
    dialogHeight: {
        minHeight: '100%',
        float: 'right',
    },
    loader: { display: 'flex', marginTop: '100px', justifyContent: 'center' },
}));

const CalibrateFormRenderer = () => {
    const classes = useStyles();

    const [responseId, setResponseId] = useState(null);
    const [changes, setChanges] = useState({});

    const [exportData, setExportData] = useState({});

    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    // Create initial QuestionnaireResponse instance
    useEffect(() => {
        const initialize = async () => {
            if (submitted) {
                return;
            }

            const data = await createResponse({}, false);
            setResponseId(data.id);
            setChanges({});
            setExportData({});
        };
        initialize();
    }, [submitted]);

    const handleSubmit = useCallback(async (submission) => {
        // hack. I can't get latest state when its in onSubmit
        let responseId;
        setResponseId((id) => {
            responseId = id;
            return id;
        });

        setLoading(true);
        try {
            console.log(submission.data);
            const response = await updateResponse(responseId, submission.data, true);

            setExportData({
                responseData: response,
                formioData: submission.data,
            });
            setSubmitted(true);
        } catch (e) {
            console.warn(e);
        }
        setLoading(false);
    }, []);

    // Track changes in form
    const handleFieldChange = (submission) => {
        if (!submission?.changed?.instance) {
            return;
        }

        const change = submission.changed;
        if (change.instance.noField) {
            return;
        }

        setChanges((changes) => ({
            ...changes,
            [change.instance.path]: change.value,
        }));
    };

    // Scrolls the page to the top
    const scrollUp = () => {
        const formWindow = document.querySelector('.MuiDialog-paperFullWidth');
        formWindow.scrollTo({ top: 0 });
    };

    // Send changes (debounced)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveChanges = useCallback(
        debounce((responseId, changes) => {
            // Remove changes that are going to be saved
            setChanges((currentChanges) => {
                return Object.fromEntries(
                    Object.entries(currentChanges)
                        .filter(([key, value]) => changes[key] !== value)
                )
            })
            saveResponseProgress(responseId, changes, false);
        }, 1000),
        []
    );

    useEffect(() => {
        if (responseId === null || Object.keys(changes).length === 0) {
            return;
        }
        saveChanges(responseId, changes);
    }, [responseId, changes, saveChanges]);

    return (
        <>
            <Dialog fullWidth style={{ float: 'right' }} open={true}>
                <DialogTitle
                    id="responsive-dialog-title"
                    classes={{ root: classes.dialogTitle }}
                    className="header-wrap"
                    style={{
                        backgroundColor: '#e3ecea',
                        border: '0px',
                        padding: '25px 24px',
                        height: '90px',
                    }}
                >
                    <Grid container direction="row" style={{ height: '100%' }}>
                        <Grid item style={{ flexGrow: 1, textAlign: 'center', height: '100%' }}>
                            <img src={CalibrateLogo} style={{ height: '100%' }} alt={'Calibrate-Logo'} />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <div style={{ backgroundColor: '#fffeff' }}>
                    <DialogContent
                        style={{
                            padding: '0px',
                            overflowX: 'hidden',
                            marginLeft: '4%',
                            paddingRight: '4%',
                        }}
                    >
                        {loading ? (
                            <Loader />
                        ) : submitted ? (
                            <ThankYou setSubmitted={setSubmitted} exportData={exportData} />
                        ) : (
                            <Form
                                form={calibrateFormschema}
                                onChange={handleFieldChange}
                                onSubmit={handleSubmit}
                                onPrevousPage={scrollUp}
                                onNextPage={scrollUp}
                                options={formOptions}
                            />
                        )}
                    </DialogContent>
                </div>
            </Dialog>
        </>
    );
};
export default CalibrateFormRenderer;
