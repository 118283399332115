import './App.css';
import Webpages from './components/Webpages';
import './Custom/Override.css';

function App() {
    return (
        <div>
            <Webpages />
        </div>
    );
}
export default App;
