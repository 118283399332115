import { Components } from '@formio/react';
import { FormEdit } from '@formio/react/lib/components';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import components from '../Custom';

Components.setComponents(components);

const calSchema = require('../schema/calibrateFormSchema.json');

const useStyles = makeStyles(() => ({
    dialog: {
        minWidth: '38rem',
        height: '100%',
    },
    title: {
        width: '98%',
        border: '15px',
    },
    closeIcon: {
        height: 24,
        width: 24,
        fontSize: 14,
        cursor: 'pointer',
        marginRight: 5,
    },
    dialogActionsRoot: {
        justifyContent: 'flex-start',
    },

    IconFont: {
        lineHeight: '0px',
    },
    dialogTitle: {
        padding: '14px 20px 0px 22px',
        borderBottom: '1px solid',
        paddingBottom: '2%',
    },
    footer: {
        paddingRight: '29px',
        border: '1px solid #efeff0',
    },
    addsave: {
        whiteSpace: 'nowrap',
    },
    paperstyles: {
        marginRight: '0px',
    },
    dialogHeight: {
        minHeight: '100%',
        float: 'right',
    },
}));

const CalibrateFormBuilder = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [inputDialog, setInputDialog] = useState(false);
    const [formSchema, setFormSchema] = useState();
    const [inputSchema, setInputSchema] = useState(calSchema);
    const [inputTestSchema, setInputTestSchema] = useState();

    const changeHandler = (schema) => {
        console.log('in changeHandler=', schema);
    };
    const saveFormHandler = (sch) => {
        console.log('in saveFormHandler=', sch);
        setFormSchema(JSON.stringify(sch));
        setOpenDialog(true);
    };
    const handleClose = () => {
        setOpenDialog(false);
    };
    const inputDialogHandler = () => {
        setInputDialog(true);
    };

    const classes = useStyles();
    return (
        <>
            <div style={{ margin: '15px' }}>
                <Button variant="contained" onClick={inputDialogHandler}>
                    Enter Form json
                </Button>{' '}
                <br></br>
                <br></br>
                <br></br>
                <Dialog fullWidth style={{ float: 'right' }} open={inputDialog}>
                    <DialogTitle id="responsive-dialog-title" classes={{ root: classes.dialogTitle }}>
                        <Grid container direction="row" style={{ backgroundColor: 'lightgrey' }}>
                            <Grid item style={{ flexGrow: 1, textAlign: 'center', width: '93%' }}>
                                <Typography variant="h2" className={classes.title} data-testid="drawerTitle">
                                    {'Enter Input Schema'}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <CloseIcon
                                    data-testid="dialog-close-icon"
                                    className={classes.closeIcon}
                                    classes={{ root: classes.IconFont }}
                                    onClick={() => setInputDialog(false)}
                                />
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent
                        style={{
                            padding: '0px',
                            overflowX: 'hidden',
                            marginTop: '2%',
                            marginLeft: '4%',
                        }}
                    >
                        <>
                            <Typography>
                                <textarea
                                    cols="60"
                                    rows="30"
                                    placeholder="Enter json here"
                                    onChange={(e) => setInputTestSchema(JSON.parse(e.target.value))}
                                ></textarea>
                            </Typography>
                        </>
                    </DialogContent>
                    <DialogActions className={classes.footer}>
                        <Button
                            data-testid="dialog-submit-button"
                            onClick={() => {
                                setInputSchema(inputTestSchema);
                                setInputDialog(false);
                            }}
                            color="primary"
                            variant="contained"
                        >
                            {'Submit'}
                        </Button>
                    </DialogActions>
                </Dialog>
                <FormEdit
                    form={inputSchema}
                    onChange={changeHandler}
                    saveText={'Save Form'}
                    saveForm={saveFormHandler}
                    options={{
                        builder: {
                            basic: {
                                components: {
                                    scaleCustomComp: true,
                                    uploadCustomComp: false,
                                },
                            },
                            premium: false,
                        },
                    }}
                />
                <Dialog fullWidth style={{ float: 'right' }} open={openDialog}>
                    <DialogTitle id="responsive-dialog-title" classes={{ root: classes.dialogTitle }}>
                        <Grid container direction="row" style={{ backgroundColor: 'lightgrey' }}>
                            <Grid item style={{ flexGrow: 1, textAlign: 'center', width: '93%' }}>
                                <Typography variant="h2" className={classes.title} data-testid="drawerTitle">
                                    {'Calibrate Form schema'}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <CloseIcon
                                    data-testid="dialog-close-icon"
                                    className={classes.closeIcon}
                                    classes={{ root: classes.IconFont }}
                                    onClick={() => {
                                        handleClose();
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent
                        style={{
                            padding: '0px',
                            overflowX: 'hidden',
                            marginTop: '2%',
                            marginLeft: '4%',
                        }}
                    >
                        <>
                            <Button
                                onClick={() => {
                                    navigator.clipboard.writeText(formSchema);
                                }}
                            >
                                Copy to clipboard
                            </Button>
                            <Typography>{formSchema}</Typography>
                        </>
                    </DialogContent>
                </Dialog>
            </div>
        </>
    );
};
export default CalibrateFormBuilder;
