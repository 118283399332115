import baseEditForm from "formiojs/components/_classes/component/Component.form";

const UploadBaseEditForm =  (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
           
            key: "placeholder",
            ignore: true
          },
         
        ]
      },
      {
        key: "data",
        components: [],
        ignore:true
      },
      {
        key: "validation",
        components: [],
        ignore:true
      },
      {
        key: "api",
        components: [],
       
      },
      {
        key: "conditional",
        components: [],
        ignore:true
      },
      {
        key: "logic",
        components: [],
        ignore:true
      },
      {
        key: "layout",
        components: [],
        ignore:true
      },
      {
        key: "addons",
        components: [],
        ignore:true
      }
    ],
    ...extend
  );
};
export default UploadBaseEditForm;
