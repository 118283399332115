import { ReactComponent } from '@formio/react';
import { Grid, Slider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import settingsForm from './Scale.settingsForm';

/**
 * Custom React component
 *
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 *
 */
const ScaleCustomComp = (props) => {
    const minValue = props.component.minValue;
    const maxValue = props.component.maxValue;

    const [value, setValue] = useState(minValue);

    // runs only on hook start
    useEffect(() => {
        props.onChange(minValue, null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeHandler = (val) => {
        setValue(val.target.value);
        props.onChange(val.target.value, null);
    };
    const marks = [];
    for (let i = minValue; i <= maxValue; i++) {
        const obj = { value: i, label: i.toString() };
        marks.push(obj);
    }

    return (
        <>
            <Grid container direction="row" alignItems="center" style={{ width: '100%' }}>
                <Grid item>{props.component.firstLabel}</Grid>
                <Grid item style={{ width: '75%', padding: '10px', marginLeft: '15px' }}>
                    <Slider
                        aria-label="Small steps"
                        step={1}
                        min={parseInt(minValue)}
                        max={parseInt(maxValue)}
                        valueLabelDisplay="auto"
                        value={value}
                        onChange={changeHandler}
                        marks={marks}
                        sx={{
                            color: '#8967E9',
                        }}
                    />
                </Grid>
                <Grid style={{ marginLeft: '20px' }} item>
                    {props.component.lastLabel}
                </Grid>
            </Grid>
        </>
    );
};

export default class Scale extends ReactComponent {
    /**
     * This function tells the form builder about your component. It's name, icon and what group it should be in.
     *
     * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
     */
    static get builderInfo() {
        return {
            title: 'Scale',
            icon: 'square',
            documentation: '',
            weight: -10,
            schema: Scale.schema(),
        };
    }

    /**
     * This function is the default settings for the component. At a minimum you want to set the type to the registered
     * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
     *
     * @param sources
     * @returns {*}
     */
    static schema() {
        return ReactComponent.schema({
            type: 'scaleCustomComp',
            label: 'scale',
        });
    }

    /*
     * Defines the settingsForm when editing a component in the builder.
     */
    static editForm = settingsForm;

    /**
     * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
     *
     * @param DOMElement
     * #returns ReactInstance
     */
    attachReact(element) {
        // console.log("***testgk element=",element.value)
        return ReactDOM.render(
            <ScaleCustomComp
                component={this.component} // These are the component settings if you want to use them to render the component.
                value={this.dataValue} // The starting value of the component.
                onChange={this.updateValue} // The onChange event to call when the value changes.
            />,
            element
        );
    }

    /**
     * Automatically detach any react components.
     *
     * @param element
     */
    detachReact(element) {
        if (element) {
            ReactDOM.unmountComponentAtNode(element);
        }
    }
}
