import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Button, Grid } from '@mui/material';
import React from 'react';

const ThankYou = ({ setSubmitted, exportData }) => {
    const copyToClipboard = (el) => {
        navigator.clipboard.writeText(JSON.stringify(el, null, 2));
    };

    const downloadData = (data, filename) => {
        const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'text/plain' });
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <>
            <Grid container justify="center" spacing={4} alignItems="center" direction="column">
                <Grid item>
                    <TaskAltIcon color="success" style={{ fontSize: '200px' }} />
                </Grid>
                <Grid item>
                    <h1>Thank You</h1>
                </Grid>
                <Grid item>
                    <h2>The form was submitted succesfully.</h2>
                    <Button
                        style={{ margin: 'auto', display: 'block' }}
                        variant="outlined"
                        onClick={() => {
                            setSubmitted(false);
                        }}
                    >
                        Create a new submission
                    </Button>
                </Grid>
                <Grid item>or</Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{
                        marginTop: '30px',
                        maxWidth: '600px',
                        width: '100%',
                    }}
                >
                    <Button
                        className="response-button"
                        variant="outlined"
                        onClick={() => copyToClipboard(exportData.formioData)}
                    >
                        Copy raw request payload JSON to clipboard
                    </Button>
                    <Button
                        className="response-button"
                        variant="outlined"
                        onClick={() => copyToClipboard(exportData.responseData)}
                    >
                        Copy response data to clipboard
                    </Button>
                    <Button
                        className="response-button bottom"
                        variant="outlined"
                        onClick={() => downloadData(exportData.formioData, 'formData.json')}
                    >
                        Download raw request payload JSON
                    </Button>
                    <Button
                        className="response-button bottom"
                        variant="outlined"
                        onClick={() => downloadData(exportData.responseData, 'formResponse.json')}
                    >
                        Download response data
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default ThankYou;
