import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CalibrateFormBuilder from './CalibrateFormBuilder';
import CalibrateFormRenderer from './CalibrateFormRenderer';

const Webpages = () => {
    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/formBuilder" element={<CalibrateFormBuilder />} />
                    <Route path="/" element={<CalibrateFormRenderer />} />
                </Routes>
            </Router>
        </div>
    );
};
export default Webpages;
