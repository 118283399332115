import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
    loader: { display: 'flex', marginTop: '100px', justifyContent: 'center' },
}));

const Loader = () => {
    const classes = useStyles();

    return (
        <div className={classes.loader}>
            <CircularProgress size={100} />
        </div>
    );
};

export default Loader;
