import axios from 'axios';

const SANDBOX_URL = 'https://api.sandbox.zusapi.com/forms-data';

const instance = axios.create({ baseURL: SANDBOX_URL });

export const createResponse = async (data, completed) => {
    const result = await instance.post(`/response`, {
        data,
        completed,
    });
    return result.data;
};

export const saveResponseProgress = async (responseId, data, complete) => {
    const result = await instance.patch(`/response/${responseId}`, {
        data,
        completed: complete,
    });
    return result.data;
};

export const updateResponse = async (responseId, data, complete) => {
    const result = await instance.put(`/response/${responseId}`, {
        data,
        completed: complete,
    });
    return result.data;
};
