import template from 'lodash/template';

/*
Used the default bootstrap wizardHeader template as reference for building this.
https://github.com/formio/formio.js/blob/master/src/templates/bootstrap/wizardHeader/form.ejs

* Adds a progress bar that shows % through the form based on # of "panels".
* Shows the "title" of all panels that are NOT marked with "hideLabel".
* Applies an "active" class to a panel's title when that panel is the current
  page OR all of the panels between it and the actual current page are marked with
  "hideLabel".
  E.g. ["Weight", "Weight2 (hidden)", "Weight3 (hidden)", "Medical"] will show
  "Weight" as active for all of the weight pages.
*/
const lodashTemplate = `
<nav aria-label="Wizard navigation" id="{{ ctx.wizardKey }}-header" ref="{{ctx.wizardKey}}-header" class="nav-header">
	<% const percent = 100 * (currentPage + 1) / panels.length %>
	<div class="progress-bar">
		<div class="progress" style="width: <%= percent %>%"></div>
	</div>

  <ul class="pagination" role="tablist">
		<% panels.forEach((panel, index) => { %>
			<% if (!panel.hideLabel) { %>
				<% const nextPanelsHidden = !panels.slice(index + 1, currentPage + 1).find(p => !p.hideLabel) %>
				<% const active = currentPage >= index && nextPanelsHidden %>

				<li class="page-item <%= active ? 'active' : '' %>" style="cursor: pointer;">
					<button tabindex="0" role="tab" class="page-link" ref="<%=wizardKey%>-link">
						<%= panel.title %>
					</button>
				</li>
			<% } else { %>
					<li class="hidden-page-item" ref="<%=wizardKey%>-link"></li>
			<% } %>
		<% }); %>
  </ul>
</nav>
`;

export const progressBar = template(lodashTemplate);
